import React from 'react'
import Stack from '@mui/joy/Stack';
import Textarea from '@mui/joy/Textarea';
import FormControl from '@mui/joy/FormControl';
import Button from '@mui/joy/Button';
import { Box, Grid } from '@mui/material';
import { updateReport} from '../../API'
import { LanguageContext, UserContext } from '../../Context';


type Props = {
  reportData?: WorkReport
  reportDataList?: WorkReport[]
  reportListChanged?: () => void
  doClose: () => void
}

const ReportAcceptConfirm: React.FC<Props> = ({ reportData, reportDataList, reportListChanged, doClose }) => {
  const l = React.useContext(LanguageContext);
  const user = React.useContext(UserContext);  

  const handleAccept = () => {
    // accept one report
    if(reportData) {
      reportData.Accepted = true
      console.log(reportData)
      updateReport(reportData)
          .then(({ status }) => {
              if (status !== 200) {
                  throw new Error('Error! Report not updated')
              }
              if (reportListChanged) reportListChanged();
          })
          .catch((err) => console.log(err))
    }
    // accept many reports
    let proms: Promise<any>[] = []
    reportDataList?.forEach(element => {
      element.Accepted = true
      proms.push(updateReport(element))
    })
    Promise.all(proms).then(reportListChanged)
  };

  const handleSaveReport = (e: React.FormEvent): void => {
    e.preventDefault()
    handleAccept();
  };
  const handleResetReport = (e: React.FormEvent): void => {
    e.preventDefault();
    doClose();
  };  

  return (
    <form onSubmit={(e) => handleSaveReport(e)} onReset={(e) => handleResetReport(e)}>
      <Stack spacing={1} maxWidth={'500px'} sx={{overflowX:'auto'}} maxHeight='100%'>      
        <FormControl>
          <Textarea
            disabled={true}
            name="confirmText"            
            variant="outlined"            
            sx={{ WebkitTextFillColor: "#000000", fontSize: "12px" }}
            value= {
              l.LANGUAGE_CODE ?
                {
                  'CS': user?.AcceptanceTextCz,
                  'EN': user?.AcceptanceTextEn,
                }[l.LANGUAGE_CODE]
                : ''
            } />
        </FormControl>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={0} style={{textAlign: 'left', width: '50%'}}>
              <Button
                type="submit">
                {l.YES}
              </Button>              
            </Grid>
            <Grid item xs={0} style={{textAlign: 'right', width: '50%'}}>
              <Button
                type="reset">
                {l.NO}
              </Button> 
            </Grid>
          </Grid>        
        </Box>
      </Stack>
    </form>
  )
}

export default ReportAcceptConfirm
