import { loginUser, getUserData } from './API'

export default class User {
  email?: string
  partner?: string
  worker?: string
  AcceptanceTextCz?: string
  AcceptanceTextEn?: string

  constructor(email?: string) {
    this.email = email
  }

  login = (password: string): Promise<loginUserApi> => {
    if(this.email)
      return loginUser(this.email, password)
    else
      throw new Error("Email not provided")
  }

  getUserData = async (): Promise<boolean> => {
    return await getUserData().then((ud) => {      
      this.email = ud.userDetail.id
      this.partner = ud.userDetail.partner
      this.worker = ud.userDetail.worker  
      this.AcceptanceTextCz = ud.userDetail.acceptanceTextCz    
      this.AcceptanceTextEn = ud.userDetail.acceptanceTextEn
      return true
    }).catch((error)=>{
      throw error
    })        
  }
}