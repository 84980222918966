import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import ReportDetailModal from './ReportDetailModal';
import { DeleteForever, MoreVert, Edit, Info, Check } from '@mui/icons-material';
import { deleteReport, updateReport } from '../../API'
import { LanguageContext } from '../../Context';


type Props = {
    reportData: WorkReport,
    reportListChanged: () => void
}

const ReportDropdownMenu: React.FC<Props> = ({ reportData, reportListChanged }) => {
    const l = React.useContext(LanguageContext);
    const [dialogProps, manageModal] = React.useState<{ open: boolean, type?: ReportDetailType }>({
        open: false,
        type: 'detail'
    });

    const handleDeleteReport = (e: React.FormEvent): void => {
        e.preventDefault()

        deleteReport(reportData)
            .then(({ status }) => {
                if (status !== 200) {
                    throw new Error('Error! Report not deleted')
                }
                reportListChanged()
            })
            .catch((err) => console.log(err))
    }

    const handleAccept = () => {
        reportData.Accepted = true
        console.log(reportData)
        updateReport(reportData)
            .then(({ status }) => {
                if (status !== 200) {
                    throw new Error('Error! Report not updated')
                }
                if (reportListChanged) reportListChanged()
            })
            .catch((err) => console.log(err))
    }
    return (
        <div>
            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                >
                    <MoreVert />
                </MenuButton>
                <Menu placement="bottom-end">
                    <MenuItem onClick={() => manageModal({ open: true, type: 'edit' })}>
                        <ListItemDecorator>
                            <Edit />
                        </ListItemDecorator>{' '}
                        {l.REPORT_MENU_EDIT}
                    </MenuItem>
                    <MenuItem onClick={() => manageModal({ open: true, type: 'detail' })}>
                        <ListItemDecorator>
                            <Info />
                        </ListItemDecorator>{' '}
                        {l.REPORT_MENU_DETAIL}
                    </MenuItem>
                    <MenuItem onClick={() => manageModal({ open: true, type: 'accept' })}>
                        <ListItemDecorator>
                            <Check />
                        </ListItemDecorator>{' '}
                        {l.REPORT_MENU_ACCEPT}
                    </MenuItem>
                    <ListDivider />
                    <MenuItem variant="soft" color="danger"
                        onClick={handleDeleteReport}>
                        <ListItemDecorator sx={{ color: 'inherit' }}>
                            <DeleteForever />
                        </ListItemDecorator>{' '}
                        {l.REPORT_MENU_DELETE}
                    </MenuItem>
                </Menu>
            </Dropdown>
            <ReportDetailModal
                openDialog={dialogProps.open}
                doClose={() => manageModal({ open: false })}
                reportType={dialogProps.type}
                reportData={reportData}
                reportListChanged={() => { reportListChanged(); manageModal({ open: false }); }}
            />
        </div>
    );
}
export default ReportDropdownMenu