import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import ReportDetail from './ReportDetail';
import ReportAcceptConfirm from './ReportAcceptConfirm';
import { LanguageContext } from '../../Context';

type Props = {
  openDialog: boolean,
  doClose: () => void,
  reportType?: ReportDetailType,
  reportData?: WorkReport,
  reportDataList?: WorkReport[],
  reportListChanged: () => void
}

const ReportDetailModal: React.FC<Props> = ({ openDialog, doClose, reportType, reportData, reportDataList, reportListChanged }) => {
  const l = React.useContext(LanguageContext);

  return (
    <Modal open={openDialog} onClose={() => { doClose() }}>
      <ModalDialog sx={{overflowX:'auto', width: '500px'}}>
        <DialogTitle>{
          reportType ?
            {
              'add': l.REPORT_DETAIL_MODAL_ADD,
              'edit': l.REPORT_DETAIL_MODAL_EDIT,
              'detail': l.REPORT_DETAIL_MODAL_DETAIL,
              'accept' : l.REPORT_ACCEPT_ACK
            }[reportType]
            : ''
        }</DialogTitle>
      
        {reportType === 'accept'
          ? <ReportAcceptConfirm reportData={reportData} reportDataList={reportDataList} reportListChanged={reportListChanged} doClose={doClose} />
          : <ReportDetail reportType={reportType ?? 'detail'} reportData={reportData} reportListChanged={reportListChanged} />
        }         
      </ModalDialog>
    </Modal>

  );
}
export default ReportDetailModal