const lang = {
    LANGUAGE_NAME: "Čeština",
    LANGUAGE_CODE: "CS",

    LOGIN_WRONG_LOGIN: "Špatné přihlášení",
    LOGIN_SERVER_ERROR: "Chyba serveru",
    LOGIN_LOGIN_HEADER: "Výkaz práce",
    LOGIN_LOGIN_SUBHEADER: "Přihlášení",
    LOGIN_FORM_EMAIL: "Email",
    LOGIN_FORM_PASSWORD: "Heslo",
    LOGIN_FORM_SUBMIT: "Přihlásit",
    LOGIN_FORM_WARNING: "Varování",

    PASSWORD_CHANGE_MISMATCH: "Hesla se neshodují",
    PASSWORD_CHANGE_WRONG: "Špatně zadané staré heslo",
    PASSWORD_CHANGE_SERVER_ERROR: "Chyba serveru",
    PASSWORD_CHANGE_OLD: "Staré heslo",
    PASSWORD_CHANGE_NEW: "Nové heslo",
    PASSWORD_CHANGE_REPEAT: "Opakovat nové heslo",
    PASSWORD_CHANGE_SUBMIT: "Změnit heslo",
    PASSWORD_CHANGE_WARNING: "Varování",

    REPORT_DETAIL_DURATION: "Trvání",
    REPORT_DETAIL_ADD: "Přidat",
    REPORT_DETAIL_EDIT: "Upravit",

    REPORT_DETAIL_MODAL_ADD: "Přidání výkazu",
    REPORT_DETAIL_MODAL_EDIT: "Editace výkazu",
    REPORT_DETAIL_MODAL_DETAIL: "Detail výkazu",

    REPORT_DETAIL_BUTTON_ADD: "Přidat výkaz",
    REPORT_DETAIL_BUTTON_ACCEPT: "Odeslat vybrané",

    REPORT_MENU_EDIT: "Editovat",
    REPORT_MENU_DETAIL: "Detail",
    REPORT_MENU_ACCEPT: "Odeslat",
    REPORT_MENU_DELETE: "Smazat",

    REPORT_ACCEPT_ACK: "Potvrzení odeslání",

    SIDE_MENU_NOT_CONFIRMED: "Neodeslané výkazy",
    SIDE_MENU_CONFIRMED: "Odeslané výkazy",
    SIDE_MENU_LOGOUT: "Odhlásit",
    SIDE_MENU_PASSWORD_CHANGE: "Změna hesla",

    NO_PROJECT_FILTER: "Všechny projekty",
    HOURS_SUM: "Celkem hodin",

    MONTHS: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],

    PROJECT: "Projekt",
    PROFESSION: "Profese",
    DATE: "Datum",
    START_TIME: "Čas od",
    END_TIME: "Čas do",
    PAUSE: "Přestávka",
    NOTE: "Poznámka",
    YES: "Ano",
    NO: "Ne",
    VERSION: "Verze",
  };
  
  export default lang;
  
  export type TLang = { [label in keyof typeof lang]: string };